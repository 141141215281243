import Vue from 'vue'
import VueRouter from 'vue-router'

const DefaultFooter = () => import(/* webpackChunkName: "default-footer" */ '@/layouts/DefaultFooter.vue')
const Default = () => import(/* webpackChunkName: "default" */ '@/layouts/Default.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/app/reports',
    component: Default,
    props: true,
    children: [
      {
        path: '',
        name: 'Reports',
        components: {
          default: () => import(/* webpackChunkName: "Reports" */ '../views/ReportsNew.vue'),
          footer: DefaultFooter
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
